<template>
  <div class="main">
    <div class="head-box">
      <!-- <img src="@/assets/img/login/left_logo.png" alt="" width="100" /> -->
      <span class="title">{{title}}</span>
      <!-- <span class="time">更新时间2022年5月20日</span> -->
    </div>

    <div class="order-list">
      <div :index="item.id + ''" v-for="item in list" :key="item.id" class="line-box">
        <span v-if="item.level == '1'" class="mulu1">{{item.content}}</span>
        <span v-else-if="item.level == '2'" class="mulu2">{{item.content}}</span>
        <span v-else-if="item.level == '3'" class="mulu3">{{item.content}}</span>
        <span v-else class="mulu4">{{item.content}}</span>
      </div>
    </div>
    <!-- <div class="btns-box">
      <el-button type="primary" @click="submitForm">同 意</el-button>
      <el-button @click="cancel">取 消</el-button>
    </div> -->
  </div>

</template>

<script>
export default {
  data() {
    return {
      title: '',
      list: []
    }
  },
  created() {
    var privacy = this.$route.query.privacy
    if (privacy == '1') {
      this.getPrivacyData()
    } else {
      this.getServicData()
    }
  },
  methods: {
    // 获取隐私数据
    getPrivacyData() {
      this.title = '颌一在线隐私条款'
      this.list = [
        {
          id: '1',
          level: '4',
          target: '',
          content:
            '颌一在线（以下统称“metajaw”或者“本服务”）尊重并保护您的隐私。您在使⽤ metajaw时，我们将按照本隐私条款收集、处理及披露您的信息。我们希望通过本隐私条款向您清晰地介绍我们对您信息的处理⽅式，因此我们建议您完整地阅读本隐私条款，以帮助您了解维护⾃⼰隐私权的⽅式。如对本隐私条款内容有任何疑问、意⻅或建议，可通过本隐私条款提供的联系⽅式与我们联系。'
        },
        { level: '3', target: '', content: '本隐私条款将帮助您了解以下内容：' },
        { id: '3', level: '4', target: '', content: '⼀、适⽤范围' },
        { id: '4', level: '4', target: '', content: '二、用户个人信息' },
        { id: '5', level: '4', target: '', content: '三、信息安全' },
        { id: '6', level: '4', target: '', content: '四、信息访问与控制' },
        { id: '7', level: '4', target: '', content: '五、未成年⼈⽤户个人信息的特别约定' },
        { id: '8', level: '4', target: '', content: '六、条款更新' },
        { id: '9', level: '4', target: '', content: '七、联系我们' },
        { id: '10', level: '1', target: 'mulu1', content: '⼀ 、适⽤范围' },
        {
          id: '11',
          level: '4',
          target: '',
          content:
            '1.1 本隐私条款适⽤于metajaw⽹站所有服务。服务包括向您提供⽹站登录和通过metajaw⽹站向您提供的相关产品技术服务。您可通过《颌一在线服务条款》知悉更多关于您存储在metajaw数据的相关政策。'
        },
        {
          id: '12',
          level: '4',
          target: '',
          content:
            '1.2 本隐私条款不适⽤于其他第三⽅向您提供的服务。例如，metajaw⽹站的服务商依托云市场向您提供服务时，您向服务商提供的信息不适⽤本隐私条款。'
        },
        {
          id: '13',
          level: '4',
          target: '',
          content:
            '1.3 作为metajaw的⽤户，若您利⽤metajaw的相关产品技术服务，为您的⽤户再⾏ 提供服务，因您的业务数据属于您所有，您应当另⾏与您的⽤户约定隐私条款。'
        },
        { id: '14', level: '1', target: 'mulu2', content: '⼆、用户个人信息' },
        { id: '15', level: '2', target: '', content: '2.1 ⽤户个人信息的收集范围和⽬的' },
        {
          id: '16',
          level: '4',
          target: '',
          content:
            '1）为完成metajaw账户的注册、管理、实名认证等必要活动，您需要提交真实、合法、有效的信息，包括但不限于名称、联系⼈、电⼦邮箱、联系电话、联系地址、银⾏账户信息、⼯商登记信息等。'
        },
        {
          id: '17',
          level: '4',
          target: '',
          content:
            '2）为提供持续服务和保证服务质量所需，我们在您使⽤本服务期间，将记录并保存您登录和使⽤本服务的⽇志信息，包括但不限于IP地址、浏览器的类型、使⽤的语⾔、访问⽇期和时间、软硬件特征信息、⽤户标识符、⽹⻚浏览和操作记录等。'
        },
        {
          id: '18',
          level: '4',
          target: '',
          content:
            '3）我们在向您提供业务功能或具体服务时，我们会按照相应的产品服务收集、使⽤、存储、对外提供及保护您的⽤户个人信息；超出本隐私条款以及相应的产品服务协议（如有）收集您的⽤户个人信息的，我们会另⾏向您说明信息收集的范围与⽬的，并征得您的同意后⽅收集提供相应服务所必要的您的信息；如您选择不提供前述信息，将会影响到您使⽤相应产品，会影响对应服务的功能。'
        },
        { id: '19', level: '2', target: '', content: '2.2 ⽤户个人信息的收集⽅法' },
        {
          id: '20',
          level: '4',
          target: '',
          content:
            '1）我们通过您主动提交、在您使⽤我们的服务过程中产⽣相应的记录等⽅式收集您的信息。'
        },
        {
          id: '21',
          level: '4',
          target: '',
          content:
            '2）我们可能会通过 Cookies 和其他相关技术收集和使⽤您的信息。我们使⽤ Cookies 和其他相关技术的具体⽤途包括：'
        },
        {
          id: '210',
          level: '4',
          target: '',
          content: '（a）记住您的身份。例如：Cookies 有助于我们辨认您作为我们的注册⽤户的身份。'
        },
        {
          id: '211',
          level: '4',
          target: '',
          content: '（b）分析您使⽤我们服务的情况，以便为您提供更加周到的个性化服务。'
        },
        {
          id: '212',
          level: '4',
          target: '',
          content:
            '您可以通过浏览器设置拒绝或管理 Cookies。但请注意，如果停⽤ Cookies，您有可能⽆法享受最佳的服务体验，某些功能的可⽤性可能会受到影响。'
        },
        { id: '22', level: '2', target: '', content: '2.3 ⽤户个人信息的使⽤' },
        {
          id: '23',
          level: '4',
          target: '',
          content:
            '为了向您提供更加优质、便捷、安全的服务，在符合相关法律法规的前提下，我们可能将收集的信息⽤作以下⽤途：'
        },
        {
          id: '24',
          level: '4',
          target: '',
          content:
            '1）为了向您提供服务，我们会向您发送信息、通知或与您进⾏业务沟通，包括但不限于为保证服务完成所必须的验证码、使⽤服务时所必要的推送通知；'
        },
        {
          id: '25',
          level: '4',
          target: '',
          content:
            '2）满⾜您的个性化需求。例如，语⾔设定、位置设定、个性化的帮助服务和指示，或对您和其他⽤户作出其他⽅⾯的回应；'
        },
        {
          id: '26',
          level: '4',
          target: '',
          content:
            '3）我们可能以⽤户个人信息统计数据为基础，设计、开发、推⼴全新的产品及服务；我们会对我们的服务使⽤情况进⾏统计，并可能会与公众或第三⽅分享这些统计信息，但这些统计信息不包含您的任何身份识别信息；'
        },
        { id: '27', level: '4', target: '', content: '4）邀请您参与有关我们产品和服务的调查；' },
        {
          id: '28',
          level: '4',
          target: '',
          content:
            '5）如超出收集⽤户个人信息时所声称的⽬的，或者其他可能需要使⽤收集的信息的相关场景，如果使⽤ 场景与初始场景⽆合理的关联性，我们会在使⽤信息前重新征得您的同意。'
        },
        { id: '29', level: '2', target: '', content: '2.3 ⽤户个人信息的共享、转让、公开披露' },
        {
          id: '30',
          level: '3',
          target: '',
          content: '2.3.1 未经您同意，我们不会与任何⽆关第三⽅共享您的信息，以下情形除外：'
        },
        {
          id: '31',
          level: '4',
          target: '',
          content: '1）获得您明确同意或基于您的主动选择，我们可能会与其他⽅共享您的⽤户个人信息；'
        },
        {
          id: '32',
          level: '4',
          target: '',
          content: '2）我们还可能因以下原因⽽分享您的信息：'
        },
        {
          id: '320',
          level: '4',
          target: '',
          content: '（a）遵守适⽤的法律法规等有关规定。'
        },
        {
          id: '321',
          level: '4',
          target: '',
          content: '（b）遵守法院判决、裁定或其他法律程序的规定。'
        },
        {
          id: '322',
          level: '4',
          target: '',
          content: '（c）遵守相关政府机关或其他有权机关的要求。'
        },
        {
          id: '323',
          level: '4',
          target: '',
          content: '（d）我们有理由确信需遵守法律法规等有关规定。'
        },
        {
          id: '324',
          level: '4',
          target: '',
          content:
            '（e）为执⾏相关服务协议或本隐私声明、处理投诉/纠纷，保护我们的客户、我们或我们的关联公司、其他⽤户或雇员的⼈身和财产安全或合法权益所合理必需的⽤途。'
        },
        {
          id: '325',
          level: '4',
          target: '',
          content: '（f）我们将与代表我们的供应商或合作伙伴共享个⼈数据，以实现本隐私条款中的⽬的。'
        },
        {
          id: '326',
          level: '4',
          target: '',
          content:
            '（g）经过您合法授权的情形。如我们因上述原因⽽共享您的信息，我们将在遵守法律法规相关规定及本条款的基础上及时告知您。'
        },
        {
          id: '327',
          level: '4',
          target: '',
          content:
            '如我们因上述原因⽽共享您的信息，我们将在遵守法律法规相关规定及本条款的基础上及时告知您。'
        },
        {
          id: '33',
          level: '3',
          target: '',
          content: '2.3.2 未经您同意，我们不会与任何⽆关第三⽅转让您的信息，以下情形除外：'
        },
        {
          id: '34',
          level: '4',
          target: '',
          content: '1）获得您明确同意或基于您的主动选择，我们可能会与其他⽅转让您的⽤户个人信息；'
        },
        {
          id: '35',
          level: '4',
          target: '',
          content:
            '2）在metajaw与其他法律主体者发⽣合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到⽤户个人信息转让，我们会要求新的持有您⽤户个人信息的公司、组织继续受本隐私条款的约束，否则我们将要求该公司、组织重新向您征求授权同意。如我们因上述原因⽽转让您的信息，我们将在遵守法律法规相关规定及本条款的基础上及时告知您。'
        },
        {
          id: '36',
          level: '3',
          target: '',
          content: '2.3.3 我们仅会在以下情况下，公开披露您的⽤户个人信息：'
        },
        {
          id: '37',
          level: '4',
          target: '',
          content: '1）获得您明确同意或基于您的主动选择，我们可能会公开披露您的⽤户个人信息；'
        },
        {
          id: '38',
          level: '4',
          target: '',
          content: '2）我们还可能因以下原因⽽公开披露您的信息：'
        },
        {
          id: '380',
          level: '4',
          target: '',
          content: '（a）遵守适⽤的法律法规等有关规定。'
        },
        {
          id: '381',
          level: '4',
          target: '',
          content: '（b）遵守法院判决、裁定或其他法律程序的规定。'
        },
        {
          id: '382',
          level: '4',
          target: '',
          content: '（c）遵守相关政府机关或其他有权机关的要求。'
        },
        {
          id: '383',
          level: '4',
          target: '',
          content: '（d）我们有理由确信需遵守法律法规等有关规定。'
        },
        {
          id: '384',
          level: '4',
          target: '',
          content:
            '（e）为执⾏相关服务协议或本隐私声明、处理投诉/纠纷，保护我们的客户、我们或我们的关联公司、其他⽤户或雇员的⼈身和财产安全或合法权益所合理必需的⽤途。'
        },
        {
          id: '385',
          level: '4',
          target: '',
          content:
            '（f）经过您合法授权的情形。如我们因上述原因⽽公开披露您的信息，我们将在遵守法律法规相关规定及本条款的基础上及时告知您。'
        },
        {
          id: '386',
          level: '4',
          target: '',
          content:
            '如我们因上述原因⽽公开披露您的信息，我们将在遵守法律法规相关规定及本条款的基础上及时告知您。'
        },
        {
          id: '39',
          level: '3',
          target: '',
          content:
            '2.3.4 以下情形中，共享、转让、公开披露您的⽤户个人信息⽆需事先征得您的授权同意，且⽆需履⾏告知义务：'
        },
        { id: '40', level: '4', target: '', content: '1）与国家安全、国防安全有关的；' },
        {
          id: '41',
          level: '4',
          target: '',
          content: '2）与公共安全、公共卫⽣、重⼤公共利益有关的；'
        },
        {
          id: '42',
          level: '4',
          target: '',
          content: '3）与犯罪侦查、起诉、审判和判决执⾏等有关的；'
        },
        {
          id: '43',
          level: '4',
          target: '',
          content: '4）出于维护您或其他个⼈的⽣命、财产等重⼤合法权益但⼜很难得到本⼈同意的；'
        },
        { id: '44', level: '4', target: '', content: '5）您⾃⾏向社会公众公开的个⼈信息；' },
        {
          id: '45',
          level: '4',
          target: '',
          content: '6）从合法公开披露的信息中收集个⼈信息的，如合法的新闻报道、政府信息公开等渠道；'
        },
        {
          id: '46',
          level: '4',
          target: '',
          content: '7）根据个⼈信息主体要求签订和履⾏合同所必需的；'
        },
        {
          id: '47',
          level: '4',
          target: '',
          content:
            '8）⽤于维护所提供的产品或服务的安全稳定运⾏所必需的，例如发现、处置产品或服务的故障；'
        },
        { id: '48', level: '4', target: '', content: '9）法律法规规定的其他情形；' },
        {
          id: '49',
          level: '4',
          target: '',
          content:
            '10）根据法律规定，共享、转让及公开披露经去标识化处理的个⼈信息，且确保数据接收⽅⽆法复原并重新识别个⼈信息主体的，不属于个⼈信息的对外共享、转让及公开披露⾏为，对此类数据的保存及处理将⽆需另⾏向您通知并征得您的同意。'
        },
        {
          id: '50',
          level: '4',
          target: '',
          content:
            '11）您应对您的⽤户业务数据来源、内容和数据使⽤负责。因您的⽤户业务数据内容违反法律法规、部⻔规章或国家政策等⽽造成的全部结果及责任均由您⾃⾏承担。'
        },
        { id: '51', level: '1', target: 'mulu3', content: '三、信息安全' },
        {
          id: '52',
          level: '4',
          target: '',
          content:
            '1）我们努⼒保障信息安全，以防信息的丢失、不当使⽤、未经授权阅览或披露。我们使⽤各种安 全技术以保障信息的安全。我们建⽴严格的管理制度和流程以保障信息的安全。如果我们的物理、技术或管理防护设施遭到破坏，导致信息被⾮授权访问、公开披露、篡改或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。'
        },
        {
          id: '53',
          level: '4',
          target: '',
          content:
            '2）您需要了解，您接⼊我们的服务所⽤的系统和通讯⽹络，有可能因我们可控范围外的因素⽽出现问题。在不幸发⽣⽤户个人信息安全事件（泄露、丢失等）后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可⾃主防范和降低⻛险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等⽅式告知您，难以逐⼀告知⽤户个人信息主体时，我们会采取合理、有效的⽅式发布公告。'
        },
        {
          id: '54',
          level: '4',
          target: '',
          content:
            '3）为更有效的保障您的信息安全，我们也希望您能够加强⾃我保护意识。我们仅在metajaw直接导致您个⼈信息泄露的范围内承担责任，因此，请您妥善保管您的账号及密码信息，避免您的个⼈信息泄露。除⾮您判断认为必要的情形下，不向任何第三⼈提供您的账号密码等个⼈信息。'
        },
        {
          id: '55',
          level: '1',
          target: 'mulu4',
          content:
            '四、信息访问与控制                                                                      '
        },
        {
          id: '56',
          level: '4',
          target: '',
          content:
            '1）您可以在使⽤我们服务的过程中，访问、修改和删除您的相关信息。您访问、修改和删除信息的⽅式将取决于您使⽤的具体服务。'
        },
        {
          id: '57',
          level: '4',
          target: '',
          content:
            '2）如您发现我们违反法律法规的规定或者双⽅的约定收集、使⽤您的信息，您可以要求我们删除。如您发现我们收集、存储的您的信息有错误的，且⽆法⾃⾏更正的，您也可以要求我们更正。'
        },
        {
          id: '58',
          level: '4',
          target: '',
          content: '4）在访问、修改和删除相关信息时，我们可能会要求您进⾏身份验证，以保障账户安全。'
        },
        {
          id: '59',
          level: '4',
          target: '',
          content:
            '5）基于法律法规要求、保障信息安全等正当事由，您的部分信息可能⽆法访问、修改和删除。'
        },
        {
          id: '60',
          level: '4',
          target: '',
          content:
            '6）您可以⾃⾏选择撤回对某些⾮基本功能或服务对您信息处理的同意，并通过联系客服的⽅式选择注销metajaw账号。'
        },
        { id: '61', level: '1', target: 'mulu5', content: '五、未成年⼈⽤户个人信息的特别约定' },
        {
          id: '62',
          level: '4',
          target: '',
          content:
            '5.1. 我们主要⾯向成⼈提供产品和服务。如您为未成年⼈，我们要求您请您的⽗⺟或监护⼈仔细阅读本隐私条款，并在征得您的⽗⺟或监护⼈同意的前提下使⽤我们的服务或向我们提供信息。'
        },
        {
          id: '63',
          level: '4',
          target: '',
          content:
            '5.2. 对于经⽗⺟或监护⼈同意使⽤我们的产品或服务⽽收集未成年⼈个⼈信息的情况，我们只会在 法律法规允许、⽗⺟或监护⼈明确同意或者保护未成年⼈所必要的情况下收集、使⽤、存储、对外提供及及保护此信息。'
        },
        { id: '64', level: '1', target: 'mulu6', content: '六、条款更新' },
        {
          id: '65',
          level: '4',
          target: '',
          content:
            '我们可能适时修订本隐私声明的条款，该等修订构成本条款的⼀部分。如可能造成您在本隐私条款下权利的实质减少或扩⼤收集、使⽤信息的范围等重要规则变更时，我们将在修订⽣效前通过在主⻚上显著位置提示或向您发送电⼦邮件或以其他⽅式通知您。在该种情况下，若您继续使⽤我们的服务，即表示同意受经修订的本隐私条款的约束。'
        },
        { id: '66', level: '1', target: 'mulu7', content: '七 、联系我们' },
        {
          id: '67',
          level: '4',
          target: '',
          content:
            '如您对本隐私条款或其他相关事宜有疑问，可发邮件⾄【product@metajaw.com】，一般情况下，我们将在30天内回复您的请求。'
        }
      ]
    },
    // 获取服务数据
    getServicData() {
      this.title = '颌一在线（metajaw）服务协议'
      this.list = [
        { level: '4', target: '', content: '欢迎您使⽤颌一在线(以下统称“metajaw”)！' },
        { id: '3', level: '1', target: '', content: '总则' },
        {
          id: '4',
          level: '4',
          target: '',
          content:
            '1）在注册和使⽤metajaw服务前，请您务必仔细阅读并透彻理解本服务协议(以下或简称“本服务协议”)，在确认充分理解后选择接受或不接受本服务协议；一旦您完成注册或开始以其他⽅式使⽤metajaw服务，即表明您已阅读并同意受本服务协议的约束。如您不同意本服务协议或其中任何条款约定，您应不再注册或使⽤metajaw服务。'
        },
        {
          id: '5',
          level: '4',
          target: '',
          content:
            '2）请您审慎阅读、充分理解各条款内容，特别是免除或者减轻责任等与您有重⼤利害关系的相应条款，免除或者减轻责任条款将以加粗或其他醒⽬形式提示您注意。当您通过⽹络⻚⾯点击同意，即表示您已充分阅读、理解并接受本协议的全部内容，并与我们达成⼀致。'
        },
        {
          level: '4',
          target: '',
          content:
            '3）基于⽹络服务的及时性、复杂性、⾼效性等特性及监管要求、政策调整等原因，您同意metajaw可以不时对本服务协议以及metajaw的相关服务规则进⾏调整，并通过⽹站公告、邮件通知、短信通知、系统消息、站内信等⽅式中的⼀种或多种予以公布；若您在调整后继续使⽤metajaw服务的，表示您已充分阅读、理解并接受修改后的内容，也将遵循修改后的内容；在本服务协议内容公告变更后，若您不同意修改后的服务协议，您有权⽴即停⽌使⽤metajaw服务。'
        },
        {
          level: '4',
          target: '',
          content:
            '4）本服务协议内容同时包括metajaw可能持续增加发布的关于所提供服务的相关协议、业务规则等内容。上述内容⼀经正式发布并以总则第3条所述向您公布，即为本服务协议不可分割的组成部分，您同样应当遵守。'
        },
        { level: '1', target: '', content: '简介' },
        {
          level: '4',
          target: '',
          content:
            '1）本服务协议是您与北京为开数字科技有限公司（以下统称“颌一在线”或者“我们”）就使⽤metajaw服务⽽订⽴的有效合约。'
        },
        {
          level: '4',
          target: '',
          content:
            '2）metajaw服务指a）metajaw产品平台系统，为您提供设计服务、打印⽣产管理、设备管理、组织架构管理等协同、管理、分析⼯具（具体以metajaw实际提供的为准）,您可以根据⾃⼰的需求选择使⽤⼀项或多项具体产品并遵守其使⽤规则；b）颌一在线专业服务，包括⻮科设计服务和其他专业服务。涉及具体服务的服务内容、服务等级、技术规范、操作⽂档、计费标准等内容的服务条款、规则、说明、标准等（统称为“服务规则”），以相关⻚⾯展示的内容为准。'
        },
        {
          level: '4',
          target: '',
          content:
            '3）客户可以通过购买等⽅式使⽤metajaw服务（包括产品平台系统和专业服务）。购买服务后，客户可以授权给其关联⽤户进⾏使⽤。关联⽤户（下⽂统称“⽤户”）可以是客户的雇员。本服务协议以下正⽂若⽆特别指出，称谓对象为“您”的条款同时适⽤客户和其⽤户。'
        },
        {
          level: '4',
          target: '',
          content:
            '本服务协议包括条款A和条款B，条款A针对所有metajaw服务（包括产品平台系统和专业服务）适⽤，条款B针对专业服务适⽤。'
        },
        {
          level: '4',
          target: '',
          content:
            '4）我们并将不断更新服务范围及服务内容，最新的metajaw服务以相关⻚⾯展示的内容以及向您实际提供的为准。您续签或新订购某项服务后，应遵守当时的服务协议，metajaw推出新的服务后，将可能更新本服务协议中相关内容。'
        },
        { id: '14', level: '1', target: '', content: '⼀、账号' },
        { id: '15', level: '4', target: '', content: '条款A通⽤条款' },
        { id: '16', level: '2', target: '', content: '1.1 账号注册' },
        {
          level: '4',
          target: '',
          content:
            '1）注册或以其他允许的⽅式实际使⽤本服务时，客户或其⽤户应当是具备完全⺠事权利能⼒和完全⺠事⾏为能⼒的⾃然⼈、法⼈或⾮法⼈组织'
        },
        {
          level: '4',
          target: '',
          content:
            '2）当按照注册⻚⾯提示填写信息、阅读并同意本服务协议且完成全部注册程序后，您可获得metajaw账户并成为metajaw的⽤户。（注册时需勾选接受条款）'
        },
        {
          level: '4',
          target: '',
          content:
            '3）您应当按照法律法规要求或按相应⻚⾯的提示，准确提供并及时更新您的账户信息，以使之真实、及时、完整和准确。如您提供的资料错误、不实、过时或不完整的，我们可以向您发出询问及/或要求改正的通知，您应按照我们的要求配合提供或者更新相关资料。因您填写的信息、资料不真实、不及时、不完整或不准确的，您应承担您不能使⽤metajaw账户（不能注册成功、或账户被冻结、注销）或您在使⽤过程中的后果和损失。'
        },
        { level: '2', target: '', content: '1.1 账号的使⽤和安全' },
        {
          level: '4',
          target: '',
          content:
            '1）metajaw账号将作为您使⽤metajaw服务的身份识别依据，您应当对⽤户名、密码等信息采取必要、有效的保密和安全保护措施（包括但不限于：保管使⽤权限、设置⾼强度密码和定期更换措施等），否则，导致的后果由您⾃⾏承担。此外，您应为账号下的⾏为负责，所有账号下的操作⾏为均被视为您实施，且应由您承担全部法律后果。'
        },
        {
          level: '4',
          target: '',
          content:
            '2）您需⾃⾏建⽴健全内部管理制度，规范对账号的使⽤与管理。若您基于⾃身经营需要等原因，将账号授权您的员⼯或他⼈管理的，须做好权限管控，并且在遇到⼈员变动时及时完成交接和账号安全保护（例如修改密码、变更登录⽅式、设置账号保护等）。在任何时候，未经帐户持有⼈的同意，您都不得使⽤其他⼈的帐户。如metajaw或第三⽅因其他⼈使⽤您的帐户或因您使⽤其他⼈的账户⽽蒙受损失，您则应承担相应责任。'
        },
        {
          level: '4',
          target: '',
          content:
            '3）为保护账号和业务等的安全，您应依照国家相关规定及metajaw流程、制度进⾏认证。(⽬前是否有该步骤)'
        },
        {
          level: '4',
          target: '',
          content:
            '4）若您发现有他⼈盗⽤您的账号及密码、或任何其他未经您合法授权的情形时，您应⽴即以有效⽅式通知颌一在线并提供必要资料（如客户资料、情况说明、证明材料及诉求等，以便颌一在线核实身份及事件）。颌一在线收到您的有效通知并核实身份后，会依据法律法规及服务规则进⾏处理。颌一在线依据本条进⾏处理产⽣的相关责任和后果由您承担。若您提供的资料存在瑕疵，导致颌一在线⽆法核实您的身份或⽆法判断您的需求等，⽽导致颌一在线未能及时处理，给您带来的损失，应由您⾃⾏承担。同时，您理解，颌一在线对您的请求进⾏处理需要合理期限，对于颌一在线采取措施前您已经产⽣的损失以及采取措施后因不可归责于颌一在线的原因导致的损失，颌一在线不承担任何责任。'
        },
        {
          level: '4',
          target: '',
          content:
            '5）您理解并同意，我们有权了解您使⽤metajaw服务的真实背景及⽬的，有权要求您如实提供真实、全⾯、准确的信息；如果我们有合理理由怀疑您提供的信息不真实、您进⾏虚假交易，或您的⾏为违反metajaw服务规则的，我们有权暂时或永久限制您账号下所使⽤的所有产品及/或服务的部分或全部功能。您理解并同意，我们有权按照国家司法、⾏政、军事、安全等机关（包括但不限于公安机关、检察机关、法院、海关、税务机关、安全部⻔等）的要求对您的个⼈信息及在本⽹站的资⾦、交易及账号等进⾏查询、冻结或扣划。'
        },
        { level: '1', target: '', content: '⼆、服务' },
        { level: '2', target: '', content: '2.1 服务开通' },
        {
          level: '4',
          target: '',
          content:
            '1）客户可联系颌一在线开通所需服务类型；⽤户可联系关联客户开通所需服务类型。客户的关联⽤户亦需遵守本服务协议的约定。'
        },
        {
          level: '4',
          target: '',
          content:
            '2）metajaw可能因产品推⼴等各种原因，在特定时期内推出优惠措施（以下统称“优惠措施”），您理解并同意，这些优惠措施可能是暂时性、阶段性或有数量限制的，也可能仅适⽤于满⾜特定条件的客户，您需要按照相应的规则购买、使⽤相应服务。'
        },
        { level: '2', target: '', content: '2.2 服务费⽤' },
        { level: '3', target: '', content: '2.2.1 客户服务费⽤' },
        {
          level: '4',
          target: '',
          content:
            '1）metajaw服务的结算⽅式可能分为预付费和后付费等类型，为保证服务及时开通或持续提供，您应当遵守本协议及使⽤服务的服务规则，并及时缴纳费⽤。'
        },
        {
          level: '4',
          target: '',
          content:
            '2）对于按⾃然⽉结算的服务，颌一在线向您提供上⼀⽉的缴费账单后，您须在5个⼯作⽇内予以核对确认或提出异议。如颌一在线未在5个⼯作⽇内收到您的确认或异议，视为客户⽆异议；您如有异议，由双⽅友好协商解决，如协商不成以颌一在线系统数据为准。颌一在线根据双⽅最后确认的⾦额开具发票。您收到颌一在线发票后在10个⼯作⽇内付费。您使⽤的服务对应的服务规则与本条约定不⼀致或双⽅另有约定的除外。'
        },
        {
          level: '4',
          target: '',
          content:
            '3）本协议项下的业务发⽣销售折让、销售退回或其他按照国家规定需要开具红字发票或重新开票的情况，颌一在线需按照国家税收相关规定向您开具红字发票或重新开票，您需按照国家税收规定退回颌一在线已开具的发票或向税局递交需颌一在线开具红字专⽤发票的有效证明。双⽅约定具'
        },
        {
          level: '4',
          target: '',
          content:
            '4）体按以下政策执⾏：（1）如果退票发⽣在发票开具当⽉且您尚未进⾏税务认证抵扣的，您退回发票即可。（2）如果您已就该发票进⾏税务认证抵扣，或者退票时间已超过开票⽇期所在当⽉的，您应向颌一在线提供开具红字发票的必要资料，如《开具红字增值税专⽤发票信息表》等。（3）对于颌一在线尚未开具发票或已开具发票但尚未交付给您的退款申请，⽆需您提供发票相关资料。若⽆法满⾜上述条件，则⽆法办理退款，后果由您⾃⾏承担，颌一在线不就此承担任何责任。在完成上述退票及开具红字发票流程后，颌一在线将在次⽉内按照您实际消费数额重新开具发票，并将您账号内现⾦部分剩余款项进⾏退款。'
        },
        { level: '3', target: '', content: '2.2.2 ⽤户服务费⽤' },
        {
          level: '4',
          target: '',
          content: '⽤户服务费⽤由⽤户与其关联客户签订相关合同条款进⾏规定。'
        },
        { level: '2', target: '', content: '2.3 服务⽀持' },
        {
          level: '4',
          target: '',
          content: '1）颌一在线向您提供售后服务，协助您解答、处理使⽤metajaw服务过程中遇到的问题。'
        },
        {
          level: '4',
          target: '',
          content:
            '2）按照不同产品和服务类型，metajaw提供必要的⽂件和数据存储服务，其中，设计订单原⽂件默认存储N时⻓，设计订单结果⽂件存储N时⻓，切⽚原始⽂件不做存储，切⽚结果⽂件存储⼀周，报表数据存储N时⻓范围内的数据；若您主动删除⽂件或数据，metajaw将在数据库同步删除，您需⾃⾏承担⽂件和数据丢失的后果和责任。'
        },
        {
          level: '4',
          target: '',
          content:
            '3）metajaw将依照法律法规要求对其系统、设备等采取基础的安全保护措施。若您对安全保护措施的要求⾼于前述基础的安全保护措施标准的，您有权根据⾃身需求购买配置更⾼的安全保护服务或另⾏配置其他安全防护软件、系统等。'
        },
        {
          level: '4',
          target: '',
          content:
            '4）您应⾃⾏对您使⽤的计算机信息系统、设备等采取必要的、有效的安全保护措施，因您未采取前述措施导致权益受损的，由您⾃⾏承担责任。'
        },
        { level: '2', target: '', content: '2.4 服务中⽌或终⽌' },
        {
          level: '4',
          target: '',
          content:
            '1）为了向您提供更加完善的服务，metajaw有权定期或不定期地对服务平台或相关设备、系统、软件等进⾏检修、维护、升级及优化等（统称“常规维护”），如因常规维护造成metajaw服务在合理时间内中断或暂停的，颌一在线⽆需为此向您承担责任。但是，颌一在线应当⾄少提前24⼩时，就常规维护事宜通知您。若因不可抗⼒、基础运营商过错等原因导致的⾮常规维护，颌一在线应及时通知您。'
        },
        {
          level: '4',
          target: '',
          content:
            '2）为保证服务的安全性和稳定性，metajaw可能进⾏机房迁移、设备更换等重⼤调整，前述情况可能导致metajaw服务在合理时间内中断或暂停，颌一在线⽆需为此向您承担责任，但是，颌一在线应提前30天通知您，您应予以配合；如您不配合进⾏调整，或者颌一在线⽆法与您取得联系，由此产⽣的后果由您⾃⾏承担。'
        },
        {
          level: '4',
          target: '',
          content:
            '3）如您购买的具体服务含有存储功能的，在该服务到期或终⽌后，对于您存储在该服务中的数据等任何信息，metajaw将根据该服务的服务规则为您保留相应期限。您应承担保留期限内产⽣的费⽤（若有），并按时结清费⽤、完成全部数据的迁移。保留期限届满后，您的前述信息将被删除。'
        },
        {
          level: '4',
          target: '',
          content:
            '4）metajaw有权根据⾃身运营安排，随时调整、终⽌部分或全部服务（包括但不限于对服务进⾏下线、迭代、整合等）。但是，metajaw应提前⾄少30天通知您，以便您做好相关数据的转移备份以及业务调整等，以保护您的合法权益。'
        },
        { level: '1', target: '', content: '三、客户权利义务' },
        {
          level: '4',
          target: '',
          content: '1）您有权依照本服务协议约定使⽤metajaw服务并获得颌一在线的技术⽀持和售后服务。'
        },
        {
          level: '4',
          target: '',
          content:
            '2）您在使⽤metajaw平台上的服务时须遵守相关法律法规及服务规则，并确保拥有法律法规要求的经营资质和能⼒，不得实施包括但不限于以下⾏为，也不得为任何违反法律法规的⾏为提供便利：（1）反对宪法所规定的基本原则的。（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统⼀的。（3）损害国家荣誉和利益的。（4）煽动⺠族仇恨、⺠族歧视，破坏⺠族团结的。（5）破坏国家宗教政策，宣扬邪教和封建迷信的。（6）散布谣⾔，扰乱社会秩序，破坏社会稳定的。（7）散布淫秽、⾊情、赌博、暴⼒、凶杀、恐怖或教唆犯罪的。（8）侮辱或诽谤他⼈，侵害他⼈合法权益的。（9）实施任何违背“七条底线”的⾏为。（10）含有法律、法规等禁⽌的其他内容的。'
        },
        {
          level: '4',
          target: '',
          content:
            '3）您在使⽤metajaw平台上的服务时须维护互联⽹秩序和安全，不得侵犯任何主体的合法权益，不得实施包括但不限于以下⾏为，也不得为其提供便利：（1）实施任何破坏或试图破坏⽹络安全的⾏为，包括但不限于以病毒、⽊⻢、恶意代码、钓⻥等⽅式，对⽹站、服务器进⾏恶意扫描、⾮法侵⼊系统、⾮法获取数据等；（2）实施任何改变或试图改变metajaw服务提供的系统配置或破坏系统安全的⾏为；（3）利⽤技术或其他⼿段破坏、扰乱metajaw服务的运营或他⼈对metajaw服务的使⽤；（4）以任何⽅式⼲扰或企图⼲扰metajaw任何产品或任何部分、功能的正常运⾏，或者制作、发布、传播上述⼯具、⽅法等；（5）因从事包括但不限于“DNS解析”、“安全服务”、“域名代理”、“反向代理”等任何业务，导致您⾃⼰频繁遭受攻击（包括但不限于DDoS攻击）且未及时更正⾏为，或未根据metajaw要求消除影响，从⽽对metajaw服务平台或他⼈产⽣影响的;（6）实施其他破坏互联⽹秩序和安全的⾏为。'
        },
        {
          level: '4',
          target: '',
          content:
            '4）作为客户，您应当按照服务规则及时、⾜额⽀付费⽤。若您未及时、⾜额⽀付费⽤的，metajaw有权拒绝开通服务或⽆需另⾏通知⽽中⽌、终⽌服务，同时可以采取以下⼀项或多项措施：要求您除应依约⽀付应付费⽤外，每逾期⼀天，还应按所⽋费⽤1‰的标准向颌一在线⽀付违约⾦，直⾄缴清全部费⽤为⽌；若您逾期付款超过15天，metajaw有权⽆需另⾏通知您即可随时采取包括但不限于提前单⽅解除协议的措施；若您逾期付款但您使⽤了预付费服务或您账号有未消耗的现⾦余额的，则颌一在线有权⽆需另⾏通知您，⽽直接将预付费⽤和现⾦余额⽤于抵扣拖⽋款项和违约⾦；删除您基于使⽤metajaw服务⽽存储或产⽣的部分或全部信息和数据。'
        },
        {
          level: '4',
          target: '',
          content:
            '5）资源使⽤合法性要求：您不应⼤量占⽤，亦不得导致如程序或进程等⼤量占⽤metajaw云计算资源（如云服务器、⽹络带宽、存储空间等）所组成的平台（以下简称“云平台”）中服务器内存、CPU或者⽹络带宽资源，并给云平台或者平台其他客户的⽹络、服务器、产品/应⽤等带来严重的、不合理的负荷，影响metajaw与其他特定⽹络、服务器正常通畅的联系，或者导致云平台产品与服务或者其他客户的服务器宕机、死机或者影响其他平台⽤户基于云平台的产品/应⽤不可访问等。⼀经发现，您的主机将被强制停机，您的metajaw账号也会被⽴即冻结，metajaw并保留追究您的法律责任的权利。'
        },
        {
          level: '4',
          target: '',
          content:
            '6）软件使⽤安全要求：未经metajaw书⾯许可的，您不应修改、翻译、改编、出租、转许可、在信息⽹络上传播或转让metajaw提供的服务或软件，也不得逆向⼯程、反编译或试图以其他⽅式发现metajaw提供的服务或软件的源代码。您不应进⾏任何破坏或试图破坏⽹络安全的⾏为（包括但不限于钓⻥，⿊客，⽹络诈骗，⽹站或空间中含有或涉嫌散播：病毒、⽊⻢、恶意代码，及对其他⽹站、服务器进⾏涉嫌攻击⾏为如扫描、嗅探、ARP欺骗、DDoS等）。您不应进⾏任何改变或试图改变metajaw提供的系统配置或破坏系统安全的⾏为。在使⽤服务时，不得以任何⽅式损坏、禁⽤、过载或妨碍任何metajaw服务器或连接到任何metajaw服务器的⽹络，也不得⼲扰任何其他客户使⽤和享有任何服务。您不得尝试通过⼊侵、密码盗取或任何其他⼿段擅⾃使⽤任何服务、其他帐户、计算机系统或连接到任何metajaw服务器或任何服务的⽹络。您不得通过任何⼿段获取或尝试获取服务未明确提供的任何资料或信息。'
        },
        {
          level: '4',
          target: '',
          content: '7）作为⽤户，您应当按照和客户签订的相关合同条款履⾏费⽤⽀付义务。'
        },
        { level: '1', target: '', content: '四、颌一在线权利义务' },
        {
          level: '4',
          target: '',
          content: '1）颌一在线应当依照本服务协议约定向您提供metajaw服务和售后⽀持。'
        },
        {
          level: '4',
          target: '',
          content:
            '2）颌一在线仅对metajaw服务本身提供运营维护，您应当保证⾃身的⽹络、设备的安全性、稳定性，如发⽣以下情况，您应及时解决并避免对metajaw服务产⽣影响：（1）您内部⽹络出现问题，包括但不限于超负荷等。（2）您⾃有设备或您使⽤的第三⽅设备出现故障。（3）您⾃⾏拆离设备或通过其他⽅式导致⽹络中断。（4）其他您⾃身原因导致的任何故障、⽹络中断等。'
        },
        {
          level: '4',
          target: '',
          content:
            '3）若颌一在线⾃⾏发现或根据相关部⻔的信息、权利⼈的投诉等发现您可能存在违反相关法律法规或本协议的⾏为的，颌一在线有权根据⼀般⼈的认识⾃⾏独⽴判断，并随时单⽅采取以下措施中的⼀种或多种：限制、暂停向您提供全部或部分metajaw服务（包括但不限于直接对您的部分服务进⾏下线并收回相关资源、对您账号采取操作限制/账号冻结等措施）；终⽌向您提供metajaw服务，终⽌协议（包括但不限于直接对您的全部服务进⾏下线并收回相关资源等）;颌一在线根据本协议终⽌向您提供服务或终⽌协议的，您预缴的费⽤将作为违约⾦颌一在线所有；依法追究您的其他责任。颌一在线依据本服务协议采取相应措施（包括但不限于暂停服务、扣除费⽤、终⽌协议等），不视为颌一在线违约，若因此给您造成损失（包括但不限于业务暂停、数据清空等，本协议中“损失”是指所有直接或间接损失、对第三⽅的违约责任、责任、费⽤(包括诉讼仲裁发⽣的律师费、案件受理及处理费等法律费⽤以及专家和顾问费)、其他收费、开⽀、诉讼、法律程序、索赔和要求，全⽂同）的，均由您⾃⾏承担。因您违反本协议约定产⽣的责任和后果由您⾃⾏承担，若给颌一在线或第三⽅造成损失的，您应当予以赔偿。'
        },
        {
          level: '4',
          target: '',
          content:
            '4）作为客户，您同意颌一在线有权使⽤您的名称、品牌、商标标识等作为商业案例进⾏宣传、展示。'
        },
        { level: '1', target: '', content: '五、客户数据' },
        {
          level: '4',
          target: '',
          content:
            '1）您保证，您存储、上传到metajaw服务中，或者利⽤metajaw服务以分析、分发等任何⽅式处理的数据，为您依法收集、获取或产⽣的数据，您不会也不曾以任何⽅式侵犯任何个⼈或实体的合法权利。'
        },
        {
          level: '4',
          target: '',
          content:
            '2）您保证其有权使⽤metajaw服务对前述数据进⾏存储、上传、分析和分发等任何处理，且前述处理活动均符合相关法律法规的要求，不存在任何违法、侵权或违反其与第三⽅的合同约定的情形，不会将数据⽤于违法违规⽬的。'
        },
        {
          level: '4',
          target: '',
          content:
            '3）您有权⾃⾏使⽤metajaw服务对数据进⾏上传、分析、删除、更改等处理（具体以您使⽤的服务的服务规则为准），就数据的删除、更改等处理，您应谨慎操作并承担因该等操作所产⽣的后果。'
        },
        {
          level: '4',
          target: '',
          content:
            '4）除因您使⽤metajaw服务所需及法律法规另有规定外，未经您同意，metajaw不会接触或使⽤您的数据。'
        },
        {
          level: '4',
          target: '',
          content:
            '5）您应根据⾃身需求⾃⾏对数据进⾏备份，metajaw仅依据相关法律法规要求或服务规则约定提供数据备份服务，metajaw仅在法定或约定提供的数据备份服务的范围内承担责任。'
        },
        {
          level: '4',
          target: '',
          content:
            '6）因您的数据的产⽣、收集、处理、使⽤等任何相关事项存在违反法律法规等情况⽽造成的全部结果及责任均由您⾃⾏承担，并且，metajaw有权随时全部或部分终⽌向您提供metajaw服务。您因此遭受的⼀切损失或承担的⼀切责任和费⽤，由您⾃⾏承担。'
        },
        { level: '1', target: '', content: '六、隐私及个⼈信息的保护' },
        {
          level: '4',
          target: '',
          content:
            '颌一在线将按照法律法规要求，采取安全保护措施，保护您的⽤户信息安全可控。具体详⻅《颌一在线数字化平台隐私条款》。'
        },
        { level: '1', target: '', content: '七、知识产权' },
        {
          level: '4',
          target: '',
          content:
            '1）metajaw所有的产品、技术与所有程序均属于颌一在线的知识产权。未经颌一在线的许可，任何⼈不得擅⾃（包括但不限于：以⾮法的⽅式复制、传播、展示、镜像、上载、下载）使⽤。否则，颌一在线将依法追究法律责任。'
        },
        {
          level: '4',
          target: '',
          content:
            '2）双⽅各⾃享有的商标权、著作权或者其他知识产权，均仍归各⽅单独享有，并不会因为双⽅签订或者履⾏本协议⽽转归对⽅享有，或者转归双⽅共同享有。除提供⽅或合法权利⼈明示同意外，另⼀⽅⽆权复制、传播、转让、许可或提供他⼈使⽤上述知识产权，否则应承担相应的责任。'
        },
        {
          level: '4',
          target: '',
          content:
            '3）任何⼀⽅均应尊重相对⽅、第三⽅的知识产权，如有任何第三⽅就⼀⽅侵犯其知识产权等合法权益的原因向另⼀⽅提起诉讼、主张索赔的，责任⽅应当独⽴处理相关纠纷，赔偿另⼀⽅的所有损失，并使另⼀⽅免责。'
        },
        { level: '1', target: '', content: '八、保密信息' },
        {
          level: '4',
          target: '',
          content:
            '作为客户，颌一在线承诺对您注册账户时或使⽤metajaw服务时提交或知悉的信息采取保密措施，不向第三⽅披露您的信息，除⾮：'
        },
        {
          level: '4',
          target: '',
          content:
            '1）依据本服务协议或者您与颌一在线之间其他服务协议、合同、在线条款等规定可以提供的；'
        },
        {
          level: '4',
          target: '',
          content: '2）依据法律法规的规定或⾏政、司法等职权部⻔要求应当提供的；'
        },
        {
          level: '4',
          target: '',
          content: '3）颌一在线通过正当⽅式取得的、⾮保密性的、同⾏业普遍了解的信息；'
        },
        {
          level: '4',
          target: '',
          content: '4）颌一在线在信息披露前已知的、且⽆义务保密的信息；'
        },
        {
          level: '4',
          target: '',
          content: '5）由第三⽅披露的且颌一在线经最审慎判断认为⽆须保密的信息；'
        },
        { level: '4', target: '', content: '6）您以书⾯形式同意颌一在线披露的保密信息；' },
        {
          level: '4',
          target: '',
          content: '7）为提供您所要求的服务，颌一在线在合理和必要的程度内向第三⽅分享您的数据；'
        },
        {
          level: '4',
          target: '',
          content: '8）在不违反本服务协议约定责任的前提下，该保密信息已经公开或能从公开领域获得。'
        },
        {
          level: '4',
          target: '',
          content:
            '作为⽤户，您理解并统⼀，您注册账户时或使⽤metajaw服务时提交或知悉的信息，由您的客户负责提供保密措施。'
        },
        { level: '1', target: '', content: '九、出⼝管制与制裁法律合规' },
        {
          level: '4',
          target: '',
          content:
            '各⽅承诺遵守所有适⽤的经济与贸易制裁以及出⼝管制法律法规，包括所有由联合国安全理事会、中国、美国及任何其他国家所制定并执⾏的制裁决议、法律与法规以及出⼝管制法律与法规（在前述⽂件适⽤于该⽅的范围内）（下称“适⽤出⼝管制法律”）。您承诺不会将metajaw提供的产品或服务⽤于适⽤出⼝管制法律所禁⽌的⽤途。⾮经相关主管机关许可，您及您授权使⽤metajaw提供的产品或服务的个⼈或实体不会通过metajaw提供的产品或服务向所适⽤出⼝管制法律所制裁或指定的个⼈或实体提供受控的技术、软件或服务，或以任何⽅式使得metajaw违反适⽤出⼝管制法律。'
        },
        { level: '1', target: '', content: '⼗、责任限制' },
        {
          level: '4',
          target: '',
          content:
            '1）您理解并同意，在使⽤metajaw服务的过程中可能会遇到以下情况使服务发⽣中断。出现下述情况时，颌一在线应及时与相关单位配合进⾏修复，但是由此给您造成的损失颌一在线将予以免责：不可抗⼒，包括但不限于⾃然灾害、政府⾏为、法律法规颁布调整、罢⼯（任⼀⽅内部劳资纠纷除外）、动乱等不能预⻅、不能避免并不能克服的客观情况；基础运营商原因，包括但不限于电信部⻔技术调整、电信/电⼒线路被他⼈破坏、电信/电⼒部⻔对电信⽹络/电⼒资源进⾏安装、改造、维护；⽹络安全事故，如计算机病毒、⽊⻢或其他恶意程序、⿊客攻击的破坏；您通过⾮颌一在线授权的⽅式使⽤metajaw服务，您操作不当或您的电脑软件、系统、硬件和通信线路出现故障；其他⾮颌一在线过错、颌一在线⽆法控制或合理预⻅的情形。'
        },
        {
          level: '4',
          target: '',
          content:
            '2）因不可抗⼒、基础运营商原因、⽹络安全事故或其他超出当事⼈可合理掌控范围的事件，造成本协议迟延履⾏或任何⼀⽅违约，双⽅均⽆需承担违约责任。但是，受影响⼀⽅应尽可能及时通知另⼀⽅。如前述事件妨碍协议履⾏达30天以上的，任⼀⽅可提前15天书⾯通知对⽅终⽌协议。因本服务协议终⽌协议的，任何⼀⽅均⽆须承担违约责任。'
        },
        {
          level: '4',
          target: '',
          content:
            '3）您理解并同意，metajaw的服务是按照现有技术和条件所能达到的现状提供的。颌一在线将尽最⼤努⼒确保服务的连贯性和安全性，但颌一在线不能保证其所提供的服务毫⽆瑕疵，因此，即使metajaw提供的服务存在瑕疵，但若上述瑕疵是当时⾏业技术⽔平所⽆法避免的，其将不视为颌一在线违约，颌一在线也⽆需承担任何责任，双⽅应友好协作共同解决问题。'
        },
        {
          level: '4',
          target: '',
          content:
            '4）于任何情形下，任⼀⽅均不对另⼀⽅之任何间接的、偶然的、特殊的或惩罚性的损害和损失承担责任，⽆论基于合同、保证、侵权或任何其他责任理论，不论任⼀⽅是否知道或应当知道上述损失或损害的可能性。'
        },
        {
          level: '4',
          target: '',
          content:
            '5）作为客户，于任何情形下，颌一在线基于本服务协议及相关订单和metajaw服务向您所承担的赔偿责任（⽆论基于合同、侵权或任何其他责任理论）的总额，不超过您就造成您损失的metajaw服务已经向metajaw⽀付的费⽤总额；如果造成您损失的相应metajaw服务的服务期限超过12个⽉的，则颌一在线的最⼤赔偿责任不超过损害发⽣前12个⽉，您就造成您损失的metajaw服务已经向颌一在线⽀付的费⽤总额（为免歧义，本处费⽤指您就实际使⽤该服务的时⻓/数量等⽽实际已⽀付的现⾦，不包括代⾦券、预付但未实际消耗的费⽤等）。若本服务协议与双⽅其他约定或metajaw服务规则冲突或不⼀致的，以本条约定为准。'
        },
        { level: '1', target: '', content: '⼗一、通知和送达' },
        {
          level: '4',
          target: '',
          content:
            '1）您应当保证和维持客户资料（包括但不限于电话号码、电⼦邮箱等联系⽅式）的有效性，客户资料若存在虚假、⽆效等任何可能导致您⽆法及时获悉业务通知、服务提示、客户服务、技术⽀持、纠纷协调、违规处罚等信息的，由您⾃⾏承担相应责任。'
        },
        {
          level: '4',
          target: '',
          content:
            '2）您应当根据颌一在线对外正式公布的联系⽅式向颌一在线发送通知，双⽅另有约定除外。'
        },
        {
          level: '4',
          target: '',
          content:
            '3）本协议项下metajaw对于您所有的通知均可通过⻚公告、站内信、电⼦邮件、⼿机短信或其他形式等⽅式进⾏；该等通知于发送之⽇视为已送达收件⼈。'
        },
        { level: '4', target: '', content: '条款B专业服务条款' },
        { level: '1', target: '', content: '⼗二、报价' },
        {
          level: '4',
          target: '',
          content:
            '颌一在线有权对metajaw的专业服务报价进⾏调整。metajaw提供的所有专业服务报价均遵循国家和地⽅政府的相关税收规定。'
        },
        { level: '1', target: '', content: '⼗三、免责条款' },
        {
          level: '4',
          target: '',
          content:
            '1）客户需对提供给metajaw的数据，以及完成的设计⽂件，分析文件和其他信息负全部责任。'
        },
        {
          level: '4',
          target: '',
          content: '2）若客户为临床医⽣，客户需负责查看和审核metajaw设计服务提供的设计⽅案。'
        },
        {
          level: '4',
          target: '',
          content:
            '3）若客户不是临床医⽣，客户需负责保证metajaw设计服务提供的设计⽅案符合其临床医⽣的要求。'
        },
        {
          level: '4',
          target: '',
          content:
            '4）对于提供给metajaw的任何信息、metajaw产出的设计结果的准确性，以及后续对应⽣产的产品，颌一在线对客户、客户的⽤户以及任何三⽅使⽤者不做保证。'
        },
        { level: '2', target: '', content: '条款更新' },
        {
          level: '4',
          target: '',
          content:
            '颌一在线有权对本服务协议及相应的服务规则内容进⾏变更，并将电⼦邮件、短信等⽅式予以公告或通知；若您在本服务协议内容变更后继续使⽤本服务的，表示您已充分阅读、理解并接受修改后的内容，也将遵循修改后的条款内容。'
        },
        { level: '4', target: '', content: '其他' },
        {
          level: '4',
          target: '',
          content: '1）除⾮另有说明，本协议正⽂及其附件中所有的“天”均为⾃然⽇，结算货币均为⼈⺠币。'
        },
        {
          level: '4',
          target: '',
          content:
            '2）本协议的订⽴、执⾏和解释及争议的解决均应适⽤中国法律（为本协议之⽬的，不包括⾹港、澳⻔特别⾏政区和台湾地区法律，也不使⽤冲突法）。如双⽅就本协议内容或其执⾏发⽣任何争议，双⽅应尽量友好协商解决；协商不成时，任何⼀⽅均可向北京市大兴区⼈⺠法院提起诉讼。'
        },
        {
          level: '4',
          target: '',
          content:
            '3）如本协议中的任何协议⽆论因何种原因完全或部分⽆效或不具有执⾏⼒，本协议的其余部分仍应有效并且有约束⼒。'
        },
        {
          level: '4',
          target: '',
          content:
            '4）本协议的拟定、解释均以中⽂为准。除双⽅另有约定外，任何有关本协议的翻译不得作为解释本协议或判定双⽅当事⼈意图之依据。'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .head-box {
    width: 60%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 25px;
    .left-logo {
      width: 120px;
    }
    .title {
      font-size: 24px;
      font-weight: 800;
      color: #333333;
    }
    .time {
      font-size: 14px;
      color: #333333;
    }
  }

  .order-list {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .line-box {
      margin-bottom: 15px;
    }
    .mulu1 {
      font-size: 19px;
      font-weight: 800;
      color: $primaryColor;
    }
    .mulu2 {
      font-size: 16px;
      font-weight: bold;
      color: $primaryColor;
    }
    .mulu3 {
      font-size: 14px;
      font-weight: bold;
      color: $primaryColor;
    }
    .mulu4 {
      font-size: 14px;
      line-height: 25px;
      color: #333333;
    }
  }
  .btns-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 35px;
  }
}
</style>
